<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
    wrapClassName="ant-modal-cust-warp"
    style="top: 5%; height: 100%; overflow-y: hidden"
  >
    <template slot="footer">
      <a-button key="back" v-if="isReadOnly" @click="handleCancel">
        关闭
      </a-button>
    </template>
    <a-spin :spinning="confirmLoading">
      
     

      <a-form :form="form">

        <a-form-item
          label="策略唯一ID"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="策略唯一ID"
            v-decorator.trim="['id', validatorRules.id]"
            disabled

          />
      </a-form-item>

        <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="运行监控状态"
      >
        <a-select
          placeholder="选择运行监控状态"
          v-decorator.trim="['status', validatorRules.status]"
        >
          <a-select-option value="OPEN">开启</a-select-option>
          <a-select-option value="CLOSE">关闭</a-select-option>
        </a-select>
      </a-form-item>
        <a-form-item
          label="挂单上下层数"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input placeholder="挂单上下层数" v-decorator.trim="['layer', validatorRules.layer]" />
        </a-form-item>
        <a-form-item
          label="最小时间间隔"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="最小时间间隔"
            v-decorator.trim="['min_time_interval', validatorRules.min_time_interval]"
          />
        </a-form-item>
        <a-form-item
          label="最大时间间隔"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="最大时间间隔"
            v-decorator.trim="['max_time_interval', validatorRules.max_time_interval]"
          />
        </a-form-item>

        <a-form-item
          label="最小下单量"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="最小下单量"
            v-decorator.trim="['min_place_order_amount', validatorRules.min_place_order_amount]"
          />
        </a-form-item>
        <a-form-item
          label="最大下单量"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="最大下单量"
            v-decorator.trim="['max_place_order_amount', validatorRules.max_place_order_amount]"
          />
        </a-form-item>

        <a-form-item
          label="买卖最小间距"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="买卖最小间距"
            v-decorator.trim="['min_buy1_sell1_diff', validatorRules.min_buy1_sell1_diff]"
          />
        </a-form-item>
        <a-form-item
          label="买卖最大间距"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="买卖最大间距"
            v-decorator.trim="['max_buy1_sell1_diff', validatorRules.max_buy1_sell1_diff]"
          />
        </a-form-item>

        <a-form-item
          label="其他最小间距"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="其他最小间距"
            v-decorator.trim="['min_other_diff', validatorRules.min_other_diff]"
          />
        </a-form-item>
        <a-form-item
          label="其他最大间距"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="其他最大间距"
            v-decorator.trim="['max_other_diff', validatorRules.max_other_diff]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { pick } from "lodash";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import {
  updateStrategy,
  queryOrganizationById,
} from "@/api/api";
export default {
  name: "StrategyAccountModal",
  components: {},
  data() {
    return {
      title: "操作",
      visible: false,
      modalWidth: 800,
      drawerWidth: 700,
      tenantList: [],
      userId: "", //保存用户id
      personType: null,
      isReadOnly: false,
      disableSubmit: false,
      dateFormat: "YYYY-MM-DD",
      treeDefaultExpandAll: true,
      validatorRules: {
        id: {
          rules: [
            {
              required: true,
              message: "策略ID不能为空",
            },
          ],
        },
        status: {
          rules: [
            {
              required: true,
              message: "请输入运行监控状态！",
            },
          ],
        },
        layer: {
          rules: [
            {
              type: "number",
              message: "挂单上下层数必须为整数！",
              transform: (value) => parseInt(value),
            },
            {
              required: true,
              message: "请输入挂单上下层数！",
            },
          ],
        },
        min_time_interval: {
          rules: [
            {
              type: "integer",
              message: "最小时间间隔必须为正整数！",
              transform: (value) => parseInt(value),
            },
            {
              validator: (rule, value, callback) => {
                const max_time_interval = this.form.getFieldValue('max_time_interval');
                if (value <= 0) {
                  callback(new Error("最小时间间隔必须为正整数！"));
                } else if (max_time_interval && value > max_time_interval) {
                  callback(new Error("最小时间间隔必须小于等于最大时间间隔！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入最小时间间隔！",
            },
          ],
        },
        max_time_interval: {
          rules: [
            {
              type: "integer",
              message: "最大时间间隔必须为正整数！",
              transform: (value) => parseInt(value),
            },
            {
              validator: (rule, value, callback) => {
                const minTimeInterval = this.form.getFieldValue('min_time_interval');
                if (value <= 0) {
                  callback(new Error("最大时间间隔必须为正整数！"));
                } else if (minTimeInterval && value <= minTimeInterval) {
                  callback(new Error("最大时间间隔必须大于最小时间间隔！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入最大时间间隔！",
            },
          ],
        },
        min_place_order_amount: {
          rules: [
            {
              type: "number",
              message: "最小下单量必须为数字！",
              transform: (value) => parseFloat(value),
            },
            {
              validator: (rule, value, callback) => {
                const maxPlaceOrderAmount = this.form.getFieldValue('max_place_order_amount');
                if (value <= 0) {
                  callback(new Error("最小下单量必须大于0！"));
                } else if (maxPlaceOrderAmount && value > maxPlaceOrderAmount) {
                  callback(new Error("最小下单量必须小于等于最大下单量！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入最小下单量！",
            },
          ],
        },
        max_place_order_amount: {
          rules: [
            {
              type: "number",
              message: "最大下单量必须为数字！",
              transform: (value) => parseFloat(value),
            },
            {
              validator: (rule, value, callback) => {
                const min_place_order_amount = this.form.getFieldValue('min_place_order_amount');
                if (value <= 0) {
                  callback(new Error("最大下单量必须大于0！"));
                } else if (min_place_order_amount && value < min_place_order_amount) {
                  callback(new Error("最大下单量必须大于等于最小下单量！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入最大下单量！",
            },
          ],
        },
        min_buy1_sell1_diff: {
          rules: [
            {
              type: "number",
              message: "买卖最小间距必须为数字！",
              transform: (value) => parseFloat(value),
            },
            {
              validator: (rule, value, callback) => {
                const maxBuySellDiff = this.form.getFieldValue('max_buy1_sell1_diff');
                if (value <= 0) {
                  callback(new Error("买卖最小间距必须大于0！"));
                } else if (maxBuySellDiff && value > maxBuySellDiff) {
                  callback(new Error("买卖最小间距必须小于等于最大间距！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入买卖最小间距！",
            },
          ],
        },
        max_buy1_sell1_diff: {
          rules: [
            {
              type: "number",
              message: "买卖最大间距必须为数字！",
              transform: (value) => parseFloat(value),
            },
            {
              validator: (rule, value, callback) => {
                const minBuySellDiff = this.form.getFieldValue('min_buy1_sell1_diff');
                if (value <= 0) {
                  callback(new Error("买卖最大间距必须大于0！"));
                } else if (minBuySellDiff && value <= minBuySellDiff) {
                  callback(new Error("买卖最大间距必须大于最小间距！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入买卖最大间距！",
            },
          ],
        },
        min_other_diff: {
          rules: [
            {
              type: "number",
              message: "其他最小间距必须为数字！",
              transform: (value) => parseFloat(value),
            },
            {
              validator: (rule, value, callback) => {
                const maxOtherDiff = this.form.getFieldValue('max_other_diff');
                if (value <= 0) {
                  callback(new Error("其他最小间距必须大于0！"));
                } else if (maxOtherDiff && value > maxOtherDiff) {
                  callback(new Error("其他最小间距必须小于等于最大间距！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入其他最小间距！",
            },
          ],
        },
        max_other_diff: {
          rules: [
            {
              type: "number",
              message: "其他最大间距必须为数字！",
              transform: (value) => parseFloat(value),
            },
            {
              validator: (rule, value, callback) => {
                const minOtherDiff = this.form.getFieldValue('min_other_diff');
                if (value <= 0) {
                  callback(new Error("其他最大间距必须大于0！"));
                } else if (minOtherDiff && value <= minOtherDiff) {
                  callback(new Error("其他最大间距必须大于最小间距！"));
                } else {
                  callback();
                }
              },
            },
            {
              required: true,
              message: "请输入其他最大间距！",
            },
          ],
        },
      },
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      uploadLoading: false,
      confirmLoading: false,
      headers: {},
      form: this.$form.createForm(this),
    };
  },
  created() {
    const token = Vue.ls.get(ACCESS_TOKEN);
    this.headers = { "X-Access-Token": token };
  },
  methods: {
    edit(record) {
      this.model = {};
      let that = this;
      that.form.resetFields();
      that.visible = true;
      that.model = { ...record }; // 将传入的record对象赋值给model对象
      that.$nextTick(() => {
        // 使用setFieldsValue方法填充表单字段值
        that.form.setFieldsValue(
          pick(
            this.model,
            "id",
            "status",
            "layer",
            "min_time_interval",
            "max_time_interval",
            "min_place_order_amount",
            "max_place_order_amount",
            "min_buy1_sell1_diff",
            "max_buy1_sell1_diff",
            "min_other_diff",
            "max_other_diff"
          )
        );
      });
    },
    close() {
      this.$emit("close");
      this.visible = false;
      this.disableSubmit = false;
      this.model = {};
    },
  
    handleOk() {
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model, values);

          let obj = updateStrategy(formData);
          
          obj.then((res) => {
              if (res.code === 200) {
                that.$emit("ok");
              } else {
                that.$message.warning(res.data.message);
              }
            })
            .finally(() => {
              that.confirmLoading = false;
              that.close();
            });
        }
      });
    },
    handleCancel() {
      this.close();
    },
  },
};
</script>

<style scoped></style>
